<template>
  <div style="background-color: #F6F4FF" class="align-center justify-center d-flex">
    <v-container class="px-0"
                 style="padding-bottom: 100px"
    >
      <div style="max-width: 530px" class="ma-auto justify-center text-center d-flex align-center mb-8">
        <v-img
          height="60"
          width="60"
          max-width="60"
          contain
          src="../../assets/logo.png"></v-img>
        <span
          style="color: #1872e1; font-size: 1.5em; letter-spacing: -1px !important; font-weight: 700 !important"
          class="ml-4 font-rubik ">examenselectivitat</span>
      </div>
      <v-card class="pa-10 rounded-xl ma-auto"
              max-width="530"
              flat>
        <div v-if="!sent" class="d-flex flex-column">
          <span class="font-rubik font-weight-bold"
                style="font-size: 23px"
          >
            Valoració general
          </span>
          <v-rating
            v-model="rating"
            background-color="#C2C4CF"
            color="yellow accent-4"
            class="mt-4 mb-2"
            dense
            hover
            size="43"
          ></v-rating>
          <span class="font-rubik" style="font-size: 14px">Premi per a qualificar</span>


          <span class="font-rubik font-weight-medium mt-10">Ressenya del producte</span>
          <v-textarea v-model="textInput"
                      placeholder="Exemple: Des que vaig comprar el dossier fa un mes, m'ha sigut molt útil per estudiar ràpid i sense distraccions. El que més/menys m'agrada del dossier és…"
                      outlined
                      class="rounded input-field mt-3 mb-8"
          ></v-textarea>

          <span class="font-rubik font-weight-medium">Tornaries a comprar un altre dossier?</span>
          <v-radio-group
            v-model="buyAnother"
            mandatory
            row
          >
            <v-radio
              label="Sí"
              color="#171C3B"
              :value="true"
            ></v-radio>
            <v-radio
              label="No"
              color="#171C3B"
              :value="false"
            ></v-radio>
          </v-radio-group>


          <span class="font-rubik font-weight-medium mt-8">Li recomanaries a un amic?</span>

          <v-radio-group
            v-model="recommendFriend"
            mandatory
            row
          >
            <v-radio
              label="Sí"
              color="#171C3B"
              :value="true"
            ></v-radio>
            <v-radio
              label="No"
              color="#171C3B"
              :value="false"
            ></v-radio>
          </v-radio-group>


          <v-btn
            @click="addReview"
            color="#171C3B"
            class="white--text text-none mt-8"
            large
            max-width="fit-content"
            depressed>
            Enviar la ressenya
          </v-btn>

        </div>


        <div v-else class="d-flex flex-column">
            <span class="font-rubik font-weight-bold"
                  style="font-size: 23px"
            >
                Gràcies per formar part de la nostra comunitat.
           </span>
          <span class="font-rubik mt-4"
                style="font-size: 23px"
          >
                Tindrem en compte la teva ressenya. 😊
           </span>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ReviewPage",
  data() {
    return {
      rating: this.$route.params.rating,
      textInput: '',
      sent: false,
      buyAnother: true,
      recommendFriend: true
    }
  },
  methods: {
    async addReview() {
      await this.axios.post('/review', {
        stripe_id: this.$route.params.stripe_id,
        rating: this.rating,
        text: this.textInput,
        buyAnother: this.buyAnother,
        recommendFriend: this.recommendFriend,
      })

      this.sent = true;
    }
  }
}
</script>

<style scoped>

.v-rating >>> button {
  border: 2px solid #F4F5FC;
  border-radius: 5px !important;
  margin-right: 4px;
}

.input-field >>> fieldset {
  border-color: #F4F5FC;
}

</style>
